
import Vue from "vue";
import tableColumns from "./TableColumns";
import {
  personalLiabilityPolicyMapActions,
  personalLiabilityPolicyMapGetters,
  personalLiabilityPolicyMapState
} from "@/store/modules/personalLiabilityPolicy";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { downloadPersonalLiabilityDocument } from "@/helpers/downloadLossForm";
import { get } from "lodash";
import { customBulkMenuOptions, PLAOptions } from "@/helpers/selectOptions";
import { IPLStatus } from "@/store/modules/personalLiabilityPolicy/types";

interface IData {
  tableActions: any[];
  tableColumns: any;
  errorMessage: string;
  isLoading: boolean;
  loading: boolean;
  successMessage: string;
  filters: any[];
  personalLiabilityId: string;
  loadingText: string;
  selectedDocuments: any;
}
export default Vue.extend({
  name: "PLApplication",
  components: {
    NoResultsFound
  },
  data(): IData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      tableActions: [],
      tableColumns,
      loading: false,
      personalLiabilityId: "",
      filters: [
        {
          field: "createdOn",
          title: "Created On",
          options: [],
          isDate: true
        },
        {
          field: "status",
          title: "Status",
          options: PLAOptions
        }
      ],
      loadingText: "",
      selectedDocuments: []
    };
  },
  methods: {
    ...personalLiabilityPolicyMapActions([
      "getPersonalLiabilityPolicies",
      "deletePersonalLiabilityPolicy",
      "updatePersonalLiabilityPolicy"
    ]),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.$router.push(`/new/personal-liability-policies`);
          break;
        case "PLApplication-activities":
          this.$router
            .push(`/admin/personal-liability-policies/activities`)
            .catch(() => {});
          break;
      }
    },
    async fetchPageData(queryObject: any): Promise<any> {
      try {
        this.loading = true;
        this.loadingText =
          "Fetching Personal Liability Policies. Please wait...";
        this.$emit("scrollTop");
        await this.getPersonalLiabilityPolicies({
          query: { ...queryObject }
        });
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },

    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      this.$router.push(`/personal-liability-policies/${_id}`);
    },
    async customBulkActionHandler(action: IPLStatus) {
      action = action.toLowerCase() as IPLStatus;
      switch (action) {
        case "rejected":
        case "submitted":
        case "received":
          {
            const message = `Are you sure you want to change this PL to ${action}?`;
            await this.$confirm(`${message}`, "Warning", {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
              closeOnClickModal: false,
              showClose: false,
              cancelButtonClass:
                "el-button rounded-none ml-0 mr-0 el-button--primary el-button--medium is-plain"
            });
            await Promise.all(
              this.selectedDocuments.map(async (pl: any) => {
                await this.updatePersonalLiabilityPolicy({
                  id: pl._id,
                  update: { status: action }
                });
              })
            );
            this.fetchPageData(
              this.$store.state.list_view_module["filterQuery"] || {}
            );
            this.$appNotifySuccess("Status Changed");
          }
          break;
        default:
          break;
      }
    },
    async createCallback(): Promise<any> {
      this.$router.push(`/new/personal-liability-policies`);
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "edit":
          this.$router.push(`/edit/personal-liability-policies/${data._id}`);
          break;
        case "view":
          this.$router.push(`/personal-liability-policies/${data._id}`);
          break;
        case "print":
          this.printCallback(data._id);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteOnePersonalLiabilityPolicy(): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Deleting Personal Liability Policy. Please wait...";
        await this.deletePersonalLiabilityPolicy(this.personalLiabilityId);
        this.$modal.hide("personalLiabilityDeleteModal");
        this.$appNotifySuccess(
          "Personal Liability Policy Successfully Deleted"
        );
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async deleteCallback(id: any): Promise<void> {
      try {
        this.personalLiabilityId = id;
        this.$modal.show("personalLiabilityDeleteModal");
      } catch (error) {
        // delete disabled
      }
    },
    async bulkActionHandler(action: string) {
      switch (action) {
        case "Print Selected":
          try {
            const documentIds = this.selectedDocuments.map(
              (document: any) => document._id
            );
            await this.printCallback(documentIds);
          } catch (e) {
            this.errorMessage = e.message;
            this.$bugSnagClient.notify(e);
          }
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
          break;
      }
    },
    async printCallback(policyLiabilityIds: string | Array<string>) {
      try {
        this.loading = true;
        this.loadingText = "Printing selected documents. Please wait...";
        const data = {
          _ids: Array.isArray(policyLiabilityIds)
            ? policyLiabilityIds
            : [policyLiabilityIds]
        };
        await downloadPersonalLiabilityDocument(data);
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    }
  },
  computed: {
    ...personalLiabilityPolicyMapState(["personalLiabilityPolicies", "links"]),
    ...personalLiabilityPolicyMapGetters(["getTotalRecords"]),
    customBulkMenuActions(): { title: string; disabled: boolean }[] {
      return this.isAdmin
        ? customBulkMenuOptions.filter(e => {
            return PLAOptions.map(pla => pla.label).includes(e.title);
          })
        : [];
    },
    isAdmin(): boolean {
      return (
        this.$getCurrentUser &&
        ["admin", "underwriting"].includes(this.$getCurrentUser.role)
      );
    },
    topButtonSecondaryItems() {
      if (!this.$isCurrentUserAdmin) {
        return null;
      }
      const primary = {
        text: "Actions",
        key: "actions"
      };
      const otherUsersOptions = [
        {
          title: "All Activities",
          command: "PLApplication-activities"
        }
      ];

      return {
        ...primary,
        subItems: otherUsersOptions
      };
    },
    tableRowActions() {
      const userId = get(this.$getCurrentUser, "_id", "");
      let tableRowActionsArray: any = [
        {
          label: "Edit",
          value: "edit",
          faIcon: "edit",
          show: (row: any) =>
            !["submitted", "received", "issued"].includes(row.status)
        },
        {
          label: "View",
          value: "view",
          faIcon: "eye",
          show: (row: any) =>
            ["submitted", "received", "issued"].includes(row.status)
        },
        { label: "Print", value: "print", faIcon: "print" }
      ];
      if (this.$isCurrentUserAdmin) {
        tableRowActionsArray.push({
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt",
          class: "bg-red-400 text-white hover:text-white hover:bg-red-500",
          show: () => (row: any) => this.isAdmin || row.createdBy === userId
        });
      }
      return tableRowActionsArray;
    },
    bulkMenuActionsData(): any {
      let bulkMenuActions = [{ title: "Print Selected", disabled: false }];
      return bulkMenuActions;
    }
  }
});
